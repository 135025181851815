import React from "react";

import P from "../component/P";
import H1 from "../component/H1";

function Prayer() {
  return (
    <div>
      <H1>Sinnesrobönen</H1>
      <P>Gud</P>
      <P>ge mig sinnesro</P>
      <P>att acceptera det jag inte kan förändra</P>
      <P>mod att förändra det jag kan</P>
      <P>och förstånd att inse skillnaden</P>
    </div>
  );
}

export default Prayer;
