import React from "react";
import Link from "@material-ui/core/Link";

import H1 from "../component/H1";
import P from "../component/P";
import UL from "../component/UL";
import InfoPaper from "../component/InfoPaper";
import { makeStyles } from "@material-ui/core/styles";

const useClasses = makeStyles({
  center: {
    textAlign: "center",
  },
});

function Home() {
  const classes = useClasses();

  return (
    <div>
      <H1>Om AA Syd Online</H1>
      <P>
        AA Syd Online är en <Link href="http://aa.se/om-aa">AA-grupp</Link> som
        arrangerar digitala AA-möten på{" "}
        <Link href="https://zoom.us/">Zoom</Link>. Gruppen har sitt ursprung i
        Skåne, men självklart får man delta i våra möten oavsett var man bor.
      </P>
      <P>
        Alla som har en önskan att sluta dricka är välkomna att delta i våra
        slutna möten. Här lyssnar vi och delar med oss av våra tankar, känslor
        och erfarenheter för att hjälpa varandra i tillfrisknandet. Våra öppna
        möten har ett liknande upplägg men är öppna för alla som vill delta.
        Detta gäller t.ex. närstående eller vem som helst som vill veta mer om
        vad AA är för något.
      </P>
      <H1>Kalender</H1>
      <P>AA Syd Online håller möten följande tider:</P>
      <UL>
        <li>söndagar kl. 20-21 (öppet möte)</li>
      </UL>
      <P>
        Sista söndagen i varje månad har vi talarmöte. Då inleds mötet med
        20 minuter där en tillfrisknande alkoholist berättar hur det var, vad som hände och hur det
        nu är med fokus på tillfrisknande i AA. Anmälan till att tala på ett
        talarmöte görs till inledaren efter något annat ordinarie möte.
      </P>
      <P>
        Sista söndagen i varje månad kl. 19-20 har vi arbetsmöte innan det
        ordinarie mötet.
      </P>
      <H1>Kontakt</H1>
      <P>
        Du kan komma i kontakt med gruppen genom att skicka ett mejl till{" "}
        <Link href="mailto:aa.syd.online@gmail.com">
          aa.syd.online@gmail.com
        </Link>
        .
      </P>
    </div>
  );
}

export default Home;
