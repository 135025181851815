import React from "react";

import P from "../component/P";
import H1 from "../component/H1";

function BlueCardClosed() {
  return (
    <div>
      <H1>Blå kortet (slutet)</H1>
      <P>Detta är ett slutet möte hos anonyma alkoholister.</P>
      <P>
        I enlighet med AA:s enda syfte så är deltagandet i slutna möten
        begränsat till personer som har en önskan att sluta dricka. Om du tror
        att du har problem med alkohol så är du välkommen att delta i detta
        möte. Vi föreslår att då vi talar om våra problem begränsar vi oss till
        hur dessa problem står i relation till alkoholism.
      </P>
    </div>
  );
}

export default React.memo(BlueCardClosed);
