import React from "react";
import { withStyles } from "@material-ui/core";
import clsx from "clsx";

const classes = (theme) => ({
  root: {
    fontWeight: 300,
    fontSize: 28,
    marginTop: 30,
    marginBottom: 10,
  },
});

function H1({ children, classes, className }) {
  return <h1 className={clsx([classes.root, className])}>{children}</h1>;
}

export default withStyles(classes)(H1);
