import React, { useCallback } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { Route } from "react-router-dom";
import clsx from "clsx";

import BlueCardClosed from "../docs/BlueCardClosed";
import Traditions from "../docs/Traditions";
import Promises from "../docs/Promises";
import Intro from "../docs/Intro";
import HowItWorks from "../docs/HowItWorks";
import Steps from "../docs/Steps";
import Prayer from "../docs/Prayer";
import SkinnyControl from "../component/SkinnyControl";
import useSkinny from "../hook/useSkinny";
import BlueCardOpen from "../docs/BlueCardOpen";
import FirstStep from "../docs/FirstStep";
import None from "../docs/None";

const classes = (theme) => ({
  formControl: {
    minWidth: 200,
  },
  skinny: {
    width: 500,
    margin: "auto",
  },
});

function Docs({ history, match, classes }) {
  const handleSelectChange = useCallback(
    (event) => {
      history.push("/docs/" + event.target.value);
    },
    [history]
  );

  const [skinny] = useSkinny();

  return (
    <div className={clsx([skinny && classes.skinny])}>
      <SkinnyControl />
      <FormControl className={classes.formControl}>
        <InputLabel>Välj dokument</InputLabel>
        <Select value={match.params.docId} onChange={handleSelectChange}>
          <MenuItem value="none">
            <div dangerouslySetInnerHTML={{ __html: "&nbsp" }} />
          </MenuItem>
          <MenuItem value="bluecard">Blå kortet (slutet)</MenuItem>
          <MenuItem value="bluecardopen">Blå kortet (öppet)</MenuItem>
          <MenuItem value="intro">AA:s deklaration</MenuItem>
          <MenuItem value="how">Hur det fungerar</MenuItem>
          <MenuItem value="prayer">Sinnesrobönen</MenuItem>
          <MenuItem value="steps">AA:s Tolv Steg</MenuItem>
          <MenuItem value="traditions">AA:s Tolv Traditioner</MenuItem>
          <MenuItem value="promises">Det nionde stegets löften</MenuItem>
          <MenuItem value="firststep">Utdrag ur första steget</MenuItem>
        </Select>
      </FormControl>
      <Route exact path="/docs/none" component={None} />
      <Route exact path="/docs/bluecard" component={BlueCardClosed} />
      <Route exact path="/docs/bluecardopen" component={BlueCardOpen} />
      <Route exact path="/docs/traditions" component={Traditions} />
      <Route exact path="/docs/promises" component={Promises} />
      <Route exact path="/docs/steps" component={Steps} />
      <Route exact path="/docs/intro" component={Intro} />
      <Route exact path="/docs/how" component={HowItWorks} />
      <Route exact path="/docs/prayer" component={Prayer} />
      <Route exact path="/docs/firststep" component={FirstStep} />
    </div>
  );
}

export default withStyles(classes)(Docs);
