import React from "react";
import { withStyles } from "@material-ui/core";
import clsx from "clsx";

const classes = (theme) => ({
  root: {
    fontWeight: 300,
    fontSize: 20,
    "& li": {
      margin: "10px 0 10px 0",
    },
  },
});

function UL({ children, classes, className }) {
  return <ul className={clsx([classes.root, className])}>{children}</ul>;
}

export default withStyles(classes)(UL);
