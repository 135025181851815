import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";

import usePrint from "../hook/usePrint";

const useStyles = makeStyles({
  root: {
    position: "fixed",
    top: 60,
    left: 20,
    margin: 0,
    "@media print": {
      display: "none",
    },
  },
});

function PrintControl() {
  const classes = useStyles();
  const [print, handlePrintChange] = usePrint();
  return (
    <FormControlLabel
      className={classes.root}
      checked={print}
      onChange={handlePrintChange}
      control={<Checkbox />}
      label="Utskrivningsläge"
      labelPlacement="end"
    />
  );
}

export default React.memo(PrintControl);
