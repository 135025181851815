import React from "react";
import Link from "@material-ui/core/Link";

import UL from "../component/UL";
import H1 from "../component/H1";

function Tel() {
  return (
    <div>
      <H1>Anslut via telefonsamtal</H1>
      <UL>
        <li>
          Klicka på länken för att ringa upp mötet:{" "}
          <Link href="tel:%2B46844682488%2C%2C%2C4883644014%23%2C%2C%2C%23">
            +46 8 4468 2488
          </Link>
          .
        </li>
        <li>Vänta. Det kan ta upp till 30 sekunder att ansluta till mötet.</li>
        <li>
          Knappa INTE inte något mer medans du väntar, även om rösten ber dig
          göra det.
        </li>
      </UL>
    </div>
  );
}

export default Tel;
