import React from "react";

import P from "../component/P";
import H1 from "../component/H1";

function FirstStep() {
  return (
    <div>
      <H1>Utdrag ur första steget</H1>
      <P>
        <i>
          "Vi erkände att vi var maktlösa inför alkoholen - att våra liv hade
          blivit ohanterliga"
        </i>
      </P>
      <P>
        Vem vill erkänna ett totalt nederlag? Knappast någon förklarligt nog.
        Alla naturliga instinkter protesterar mot tanken på personlig
        maktlöshet. Det är verkligen hemskt att behöva erkänna att vi - med glas
        i hand - blivit så besatta av ett destruktivt drickande att endast Guds
        försyn kan befria oss.
      </P>
      <P>
        Inget annat sammanbrott är som detta. Alkoholen har blivit den
        skoningslöse, som berövar oss all självständighet och motståndsvilja.
        När vi väl accepterat detta bistra faktum, är vårt sammanbrott som
        fungerande människor fullständigt.
      </P>
      <P>
        Men när vi går med i AA får vi snart en helt annan syn på denna totala
        förödmjukelse. Vi inser att det endast är genom det fullständiga
        nederlaget vi förmår att ta våra första steg mot befrielse och styrka.
        Erkännandet av vår egen personliga maktlöshet visar sig slutligen vara
        den fasta grund på vilken lyckliga och meningsfyllda liv kan byggas.
      </P>
      <P>
        Vi vet att en alkoholist som kommer till AA knappast får ut särskilt
        mycket innan han accepterat sin förödande svaghet och alla dess följder.
        Hans nykterhet - om han alls har någon - blir högst vansklig så länge
        han inte är tillräckligt ödmjuk. Verklig lycka kommer han inte att
        finna. Den ofantligt stora erfarenheten inom AA har tydligt visat att
        ödmjukhet är en av hörnstenarna i gemenskapen. Principen att vi inte
        kommer att finna någon varaktig styrka förrän vi erkänner vart
        fullständiga nederlag är roten ur vilken vår gemenskap vuxit och
        blomstrat.
      </P>
    </div>
  );
}

export default React.memo(FirstStep);
