import React from "react";

import H1 from "../component/H1";
import P from "../component/P";

function Intro() {
  return (
    <div>
      <H1>Anonyma alkoholister</H1>
      <P>
        Anonyma Alkoholister är en gemenskap av människor som delar sina
        erfarenheter, sin styrka och sitt hopp med varandra för att försöka lösa
        sitt gemensamma problem och hjälpa andra att tillfriskna från
        alkoholism.
      </P>
      <P>
        Det enda villkoret för medlemskap är en önskan att sluta dricka. AA
        kräver inga inträdes- eller medlemsavgifter. Vi är självförsörjande
        genom egna bidrag.
      </P>
      <P>
        AA har ingen anknytning till någon religion, politisk organisation eller
        institution, ej heller till annan rörelse av något slag. Vi deltar inte
        i offentliga debatter och framträder varken som förespråkare för eller
        motståndare till något annat. Vårt främsta syfte är att fortsätta vara
        nyktra och hjälpa andra alkoholister att uppnå nykterhet.
      </P>
    </div>
  );
}

export default Intro;
