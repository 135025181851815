import React, { useCallback, useState } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { MuiThemeProvider } from "@material-ui/core/styles";

import Layout from "./Layout";
import theme from "./theme";
import { BrowserRouter, Route } from "react-router-dom";
import SkinnyContext from "./context/SkinnyContext";
import PrintContext from "./context/PrintContext";
import { getFromLocalStorage, setToLocalStorage } from "./storage";

const SKINNY_KEY = "skinny";
const PRINT_KEY = "print";

function App() {
  const [skinny, setSkinny] = useState(
    getFromLocalStorage(SKINNY_KEY) === "true"
  );
  const [print, setPrint] = useState(getFromLocalStorage(PRINT_KEY) === "true");

  const handleSkinnyChange = useCallback((e) => {
    const value = e.target.checked;
    setSkinny(value);
    setToLocalStorage({ key: SKINNY_KEY, value });
  }, []);

  const handlePrintChange = useCallback((e) => {
    const value = e.target.checked;
    setPrint(value);
    setToLocalStorage({ key: PRINT_KEY, value });
  }, []);

  return (
    <SkinnyContext.Provider value={[skinny, handleSkinnyChange]}>
      <PrintContext.Provider value={[print, handlePrintChange]}>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <BrowserRouter>
            <Route render={(props) => <Layout {...props} />} />
          </BrowserRouter>
        </MuiThemeProvider>
      </PrintContext.Provider>
    </SkinnyContext.Provider>
  );
}

export default App;
