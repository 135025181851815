import React from "react";
import Link from "@material-ui/core/Link";

import H1 from "../component/H1";
import P from "../component/P";
import UL from "../component/UL";

function Newcomer() {
  return (
    <div>
      <H1>Ny i AA?</H1>
      <P>
        Att komma till insikt att man har problem med alkohol och söka hjälp för
        detta kräver en hel del mod, och som nykomling kan det lätt blir väldigt
        mycket att ta in på samma gång. Det viktigaste att veta är att AA finns
        där för alla som har en önskan att sluta dricka och ställer inga andra
        krav på dig.
      </P>
      <P>
        Här hittar du länkar till hemsidan för Anonyma Alkoholisters svenska
        servicekontor med information du i lugn och ro kan ta del av innan,
        eller efter, ditt första AA-möte.
      </P>
      <UL>
        <li>
          <Link href="https://www.aa.se/medlemmar/nyiaa/">Ny i AA?</Link>
        </li>
        <li>
          <Link href="https://aa.se/">AA Sveriges hemsida</Link>
        </li>
        <li>
          <Link href="https://www.aa.se/om-aa/">Om AA</Link>
        </li>
        <li>
          <Link href="https://www.aa.se/brochyrer/">Digitala broschyrer</Link>
        </li>
        <li>
          <Link href="https://www.aa.se/hitta-ett-mote/">Sök AA-möten</Link>
        </li>
      </UL>
    </div>
  );
}

export default Newcomer;
