import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const COLORS = ["#D7CCC8", "#CFD8DC"];

const classes = {
  showAllRoot: {
    marginBottom: 10,
  },
  showAll: {
    padding: 10,
  },
  showAllEven: {
    backgroundColor: COLORS[0],
  },
  showAllOdd: {
    backgroundColor: COLORS[1],
  },
  label: {
    fontSize: 14,
    fontWeight: 400,
    marginBottom: 10,
    textDecoration: "underline",
  },
};

function Options({ options, showAll = false, value, classes }) {
  const res = options
    .filter(({ value: optionValue }) => showAll || optionValue === value)
    .map(({ content, label }, i) => {
      return (
        <div
          key={i}
          className={clsx(
            showAll && classes.showAll,
            showAll &&
              (Math.floor(i % 2) === 0
                ? classes.showAllEven
                : classes.showAllOdd)
          )}
        >
          {showAll && <div className={classes.label}>{label}</div>}
          {content}
        </div>
      );
    });
  return showAll ? <div className={classes.showAllRoot}>{res}</div> : res;
}

Options.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      content: PropTypes.node.isRequired,
      label: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  showAll: PropTypes.bool,
  value: PropTypes.any.isRequired,
};

export default withStyles(classes)(Options);
