import React from "react";

import P from "../component/P";
import H1 from "../component/H1";

function BlueCardOpen() {
  return (
    <div>
      <H1>Blå kortet (öppet)</H1>
      <P>Detta är ett öppet möte hos anonyma alkoholister.</P>
      <P>
        Vi är glada över att ni alla är här - speciellt nykomlingar. I linje med
        vårt enda syfte och med vår tredje tradition som säger att det enda som
        behövs för medlemskap i AA är en önskan att sluta dricka ber vi alla som
        deltar begränsa sig till att tala om sina egna problem med alkohol.
      </P>
    </div>
  );
}

export default React.memo(BlueCardOpen);
