import React from "react";

import H1 from "../component/H1";
import P from "../component/P";

function Promises() {
  return (
    <div>
      <H1>Det nionde stegets löften</H1>
      <P>Vi kommer att uppleva en ny frihet och en ny lycka.</P>
      <P>
        Vi kommer inte att ångra det förflutna eller vilja stänga dörren om det.
      </P>
      <P>Vi kommer att förstå ordet sinnesro.</P>
      <P>Vi kommer att uppleva frid.</P>
      <P>
        Oavsett hur djupt vi har sjunkit, kommer vi att upptäcka att vår
        erfarenhet kan vara till nytta för andra.
      </P>
      <P>Den där känslan av värdelöshet och självömkan kommer att försvinna.</P>
      <P>
        Vi kommer att förlora intresset för själviska ting och vinna intresse
        för våra medmänniskor.
      </P>
      <P>Själviskheten kommer att tyna bort.</P>
      <P>Hela vår livsinställning och livssyn kommer att förändras.</P>
      <P>
        Rädslan för människor och för ekonomisk otrygghet kommer att lämna oss.
      </P>
      <P>
        Vi kommer att intuitivt veta hur vi ska handskas med situationer som
        tidigare gjorde oss förvirrade.
      </P>
      <P>
        Vi kommer plötsligt att inse att Gud gör för oss det vi inte kunde göra
        för oss själva.
      </P>
      <P>
        Är detta överdrivna löften? Vi tror inte det. De infrias bland oss –
        ibland snabbt, ibland långsamt. De kommer alltid att förverkligas om vi
        arbetar för dem.
      </P>
    </div>
  );
}

export default Promises;
