import React from "react";

import H1 from "../component/H1";
import OL from "../component/OL";

function Traditions() {
  return (
    <div>
      <H1>AA:s Tolv Traditioner</H1>
      <OL>
        <li>
          Vår gemensamma välfärd bör komma först; personligt tillfrisknande
          beror på enigheten i AA.
        </li>
        <li>
          För syftet med vår grupp finns det endast en högsta auktoritet– en
          kärleksfull Gud såsom Han kan komma till uttryck i vårt gruppsamvete.
          Våra ledare är endast betrodda tjänare, de styr inte.
        </li>
        <li>Det enda kravet för medlemskap är en önskan att sluta dricka.</li>
        <li>
          Varje grupp bör vara självständig utom i angelägenheter som påverkar
          andra grupper eller AA som helhet.
        </li>
        <li>
          Varje grupp har endast ett huvudsyfte – att föra budskapet vidare till
          den alkoholist som fortfarande lider.
        </li>
        <li>
          En AA-grupp bör aldrig gå i borgen för, bekosta eller låna ut AA:s
          namn till någon närbesläktad sammanslutning eller något utomstående
          företag, så att inte problem med pengar, egendom eller anseende
          avleder oss från vårt huvudsyfte.
        </li>
        <li>
          Varje AA-grupp bör vara helt självförsörjande och inte ta emot bidrag
          utifrån.
        </li>
        <li>
          Anonyma Alkoholister bör alltid förbli icke-professionellt, men våra
          servicekontor kan anställa personal för speciella uppgifter.
        </li>
        <li>
          AA som sådant bör aldrig organiseras, men vi kan tillsätta styrelser
          eller kommittéer för service som är direkt ansvariga inför dem de
          tjänar.
        </li>
        <li>
          Anonyma Alkoholister har ingen åsikt i yttre angelägenheter; därför
          bör AA:s namn aldrig dras in i offentliga tvister.
        </li>
        <li>
          Vår PR-policy grundar sig på dragningskraft snarare än
          reklamkampanjer; vi behöver alltid bibehålla personlig anonymitet
          gentemot press, radio och film.
        </li>
        <li>
          Anonymitet är den andliga grundvalen för alla våra Traditioner och
          påminner oss ständigt om att sätta principer före personligheter.
        </li>
      </OL>
    </div>
  );
}

export default Traditions;
