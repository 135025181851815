import React from "react";

import H1 from "../component/H1";
import OL from "../component/OL";

function Steps() {
  return (
    <div>
      <H1>AA:s Tolv Steg</H1>
      <OL>
        <li>
          Vi erkände att vi var maktlösa inför alkoholen – att våra liv hade
          blivit ohanterliga.
        </li>
        <li>
          Kom till tro att en Kraft större än vi själva kunde återge oss mental
          hälsa.
        </li>
        <li>
          Beslöt att överlämna vår vilja och våra liv till Guds omsorg,{" "}
          <i>sådan vi uppfattade Honom</i>.
        </li>
        <li>
          Gjorde en grundlig och orädd moralisk inventering av oss själva.
        </li>
        <li>
          Erkände inför Gud, oss själva och en annan människa den exakta
          innebörden av våra fel.
        </li>
        <li>
          Var helt och hållet beredda att låta Gud avlägsna alla dessa
          karaktärsdefekter.
        </li>
        <li>Bad Honom ödmjukt att avlägsna våra brister.</li>
        <li>
          Gjorde upp en lista över alla personer vi hade gjort illa och blev
          villiga att gottgöra dem alla.
        </li>
        <li>
          Gottgjorde så långt möjligt dessa människor personligen, utom då det
          skulle kunna skada dem eller andra.
        </li>
        <li>
          Fortsatte att göra personlig inventering och erkände genast när vi
          hade fel.
        </li>
        <li>
          Sökte genom bön och meditation att förbättra vår medvetna kontakt med
          Gud, <i>sådan vi uppfattade Honom</i>, varvid vi endast bad att få
          kunskap om Hans vilja med oss och styrka att utföra den.
        </li>
        <li>
          När vi, som resultatet av dessa steg, hade haft ett andligt
          uppvaknande, försökte vi föra detta budskap vidare till alkoholister
          och tillämpa dessa principer i alla våra angelägenheter.
        </li>
      </OL>
    </div>
  );
}

export default Steps;
