import React from "react";

import H1 from "../component/H1";
import P from "../component/P";
import OL from "../component/OL";

function HowItWorks() {
  return (
    <div>
      <H1>Hur det fungerar</H1>
      <P>(utdrag ur kapitel 5, Anonyma Alkoholister "Stora Boken")</P>
      <P>
        Sällan har vi sett en människa misslyckas som noggrant har följt vår
        väg. De som inte tillfrisknar är de som inte helhjärtat kan eller vill
        underkasta sig detta enkla program, oftast män och kvinnor som är så
        skapta att de saknar förmåga att vara ärliga mot sig själva. Det finns
        sådana olyckliga människor. Det är inte deras fel, de tycks vara födda
        så. De är av naturen oförmögna att förstå och utveckla ett levnadssätt
        som kräver omutlig ärlighet. Deras chanser är mindre än för
        genomsnittet. Det finns också de som lider av allvarliga känslomässiga
        och mentala störningar, men många av dem tillfrisknar om de har förmågan
        att vara ärliga.
      </P>
      <P>
        Våra berättelser avslöjar i stora drag hur vi var, vad som hände och hur
        vi är nu. Om du har beslutat att du vill ha vad vi har och är villig att
        gå hur långt som helst för att uppnå det – då är du mogen att ta vissa
        steg.
      </P>
      <P>
        Inför några av dessa ryggade vi tillbaka. Vi trodde vi kunde finna en
        lättare, mindre krävande väg. Men det kunde vi inte. Vi ber dig, med
        allt det allvar vi förfogar över, att vara oförskräckt och grundlig från
        första början. Några av oss har försökt att hålla fast vid våra gamla
        föreställningar och resultatet var noll, tills vi släppte taget helt och
        hållet.
      </P>
      <P>
        Kom ihåg att det är alkohol vi har att göra med – listig, gåtfull och
        mäktig! Utan hjälp är den för mycket för oss. Men det finns En som har
        all makt – denne Ende är Gud. Må du finna Honom nu!
      </P>
      <P>
        Halvmesyrer gav oss ingenting. Vi stod vid vändpunkten. Vi överlämnade
        oss helt och fullt och bad om Hans beskydd och omsorg.
      </P>
      <P>
        Här är de steg vi tog, vilka vi föreslår som ett program för
        tillfrisknande:
      </P>
      <OL>
        <li>
          Vi erkände att vi var maktlösa inför alkoholen – att våra liv hade
          blivit ohanterliga.
        </li>
        <li>
          Kom till tro att en Kraft större än vi själva kunde återge oss mental
          hälsa.
        </li>
        <li>
          Beslöt att överlämna vår vilja och våra liv till Guds omsorg,{" "}
          <i>sådan vi uppfattade Honom</i>.
        </li>
        <li>
          Gjorde en grundlig och orädd moralisk inventering av oss själva.
        </li>
        <li>
          Erkände inför Gud, oss själva och en annan människa den exakta
          innebörden av våra fel.
        </li>
        <li>
          Var helt och hållet beredda att låta Gud avlägsna alla dessa
          karaktärsdefekter.
        </li>
        <li>Bad Honom ödmjukt att avlägsna våra brister.</li>
        <li>
          Gjorde upp en lista över alla personer vi hade gjort illa och blev
          villiga att gottgöra dem alla.
        </li>
        <li>
          Gottgjorde så långt möjligt dessa människor personligen, utom då det
          skulle kunna skada dem eller andra.
        </li>
        <li>
          Fortsatte att göra personlig inventering och erkände genast när vi
          hade fel.
        </li>
        <li>
          Sökte genom bön och meditation att förbättra vår medvetna kontakt med
          Gud, <i>sådan vi uppfattade Honom</i>, varvid vi endast bad att få
          kunskap om Hans vilja med oss och styrka att utföra den.
        </li>
        <li>
          När vi, som resultatet av dessa steg, hade haft ett andligt
          uppvaknande, försökte vi föra detta budskap vidare till alkoholister
          och tillämpa dessa principer i alla våra angelägenheter.
        </li>
      </OL>
      <P>
        Många av oss utbrast: "Vilka krav! Det klarar jag aldrig". Tappa inte
        modet. Ingen av oss har ens tillnärmelsevis lyckats följa dessa
        principer. Vi är inte helgon. Det viktiga är att vi är villiga att
        tillväxa i andlig riktning. De principer vi upptecknat visar vägen till
        framgång. Vi eftersträvar andliga framsteg snarare än andlig
        fullkomlighet.
      </P>
      <P>
        Vår beskrivning av alkoholisten, kapitlet till agnostikern och våra
        personliga äventyr före och efter klargör tre väsentliga idéer:
      </P>
      <OL type="a">
        <li>att vi var alkoholister och inte kunde hantera våra egna liv.</li>
        <li>
          att förmodligen ingen mänsklig kraft kunde ha lindrat vår alkoholism.
        </li>
        <li>att Gud kunde och skulle göra det – om vi sökte Honom.</li>
      </OL>
    </div>
  );
}

export default HowItWorks;
