import React from "react";
import Link from "@material-ui/core/Link";
import { withStyles } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";

import P from "../component/P";
import UL from "../component/UL";

const classes = {
  bold: {
    fontWeight: "bold",
  },
  mono: {
    fontFamily: "Roboto Mono",
  },
  svg: {
    width: 32,
    height: 32,
    color: "rgba(255, 255, 255, 0.85)",
  },
  svgText: {
    color: "rgba(255, 255, 255, 0.6)",
  },
  svgWrapper: {
    display: "flex",
    flexDirection: "column",
    fontWeight: 400,
    fontSize: 14,
    alignItems: "center",
    background: "linear-gradient(0deg, rgb(30,30,30) 0%, rgb(70,70,70) 100%)",
    padding: "4px 30px 4px 30px",
  },
  svgWrapperWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  link: {
    wordBreak: "break-all",
  },
};

function HowTo({ classes }) {
  return (
    <div>
      <P>
        AA Syd Online använder tjänsten{" "}
        <Link href="https://zoom.us/">Zoom</Link> för sina möten. Du kan ansluta
        till <i>Zoom</i> från din dator eller telefon.
      </P>
      <P>
        Anslut gärna till mötet 10-15 minuter innan utsatt starttid, för att
        hinna lösa eventuella tekniska problem innan mötet börjar. Kontakta
        inledaren innan mötet om du behöver hjälp med t.ex. bild, ljud eller
        mikrofon. Efter att mötet börjat har vi inte längre någon möjlighet att
        hjälpa dig.
      </P>
      <P>
        När du INTE pratar är det viktigt att du temporärt stänger av din
        mikrofon (det räcker inte med att bara vara tyst). Det blir annars
        väldigt mycket bakgrundsljud och svårt att höra vad som sägs.
      </P>
      <P className={classes.bold}>
        Du stänger av/sätter på din mikrofon med knappen <i>Mute</i> nere till
        vänster på skärmen:
      </P>
      <div className={classes.svgWrapperWrapper}>
        <Paper className={classes.svgWrapper}>
          <svg className={classes.svg} viewBox="0 0 24 24">
            <path
              fill="currentColor"
              d="M12,2A3,3 0 0,1 15,5V11A3,3 0 0,1 12,14A3,3 0 0,1 9,11V5A3,3 0 0,1 12,2M19,11C19,14.53 16.39,17.44 13,17.93V21H11V17.93C7.61,17.44 5,14.53 5,11H7A5,5 0 0,0 12,16A5,5 0 0,0 17,11H19Z"
            />
          </svg>
          <div className={classes.svgText}>Mute</div>
        </Paper>
      </div>
      <br />
      <P>Gör så här för att ansluta till ett möte:</P>
      <UL>
        <li>
          Öppna länken:
          <br />
          <Link
            className={classes.link}
            href="https://us02web.zoom.us/j/4883644014?pwd=SHZOdjVScXpCU1VMTy90QTNzYVNWdz09"
          >
            https://us02web.zoom.us/j/4883644014?pwd=SHZOdjVScXpCU1VMTy90QTNzYVNWdz09
          </Link>
          .
        </li>
        <li>
          Första gången du använder <i>Zoom</i> behöver du ladda ner programmet
          till din dator eller telefon.
        </li>
        <li>
          Skriv in <span className={classes.mono}>4883644014</span> som{" "}
          <i>Meeting ID</i> om det behövs.
        </li>
        <li>
          Skriv in <span className={classes.mono}>281056</span> som{" "}
          <i>Passcode</i> om det behövs.
        </li>
        <li>Välj att använda datorns/telefonens ljud över internet.</li>
        <li>Kontakta inledaren om du behöver hjälp.</li>
      </UL>
    </div>
  );
}

export default withStyles(classes)(HowTo);
