import React from "react";
import { withStyles } from "@material-ui/core";

const classes = (theme) => ({
  root: {
    fontWeight: 300,
    fontSize: 20,
    "& li": {
      margin: "10px 0 10px 0",
    },
  },
});

function OL({ children, classes, type }) {
  return (
    <ol className={classes.root} type={type}>
      {children}
    </ol>
  );
}

export default withStyles(classes)(OL);
