import React from "react";

import P from "../component/P";
import H1 from "../component/H1";

function Meditation() {
  return (
    <div>
      <H1>Meditation</H1>
      <P>
        Eftersom det finns tid över tänker jag att vi ska meditera en stund. Vi
        sluter ögonen och låter tankarna komma och gå precis som molnen framför
        månen. Håll inte fast och grubbla utan acceptera alla tankar som kommer
        och släpp sedan taget om dem. Om, och om, igen. Inga tankar är fel utan
        allt är okej och allt kan passera. Vi sitter i ... minuter och jag
        kommer efter det att gå på avslut.
      </P>
    </div>
  );
}

export default Meditation;
