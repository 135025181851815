import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles({
  root: {
    display: "inline-block",
    width: "100%",
    padding: "0 20px",
    border: "2px solid black",
  },
});

function InfoPaper({ children }) {
  const classes = useStyles();

  return <Paper className={classes.root}>{children}</Paper>;
}

export default React.memo(InfoPaper);
