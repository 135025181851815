import React from "react";
import Link from "@material-ui/core/Link";

import P from "../component/P";

function None() {
  return (
    <div>
      <P>
        Här har vi samlat AA-relaterade dokument och texter som kan vara till
        nytta för inledare och läsare.
      </P>
      <P>
        Är du intresserad av AA-litteratur kan du köpa detta från{" "}
        <Link href="https://www.yourvismawebsite.com/aa-forlaget/bocker">
          AA-förlagets webbshop
        </Link>
        .
      </P>
    </div>
  );
}

export default React.memo(None);
