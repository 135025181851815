import React from "react";
import { Link, Redirect, Route, Switch } from "react-router-dom";
import Button from "@material-ui/core/Button";
import clsx from "clsx";
import makeStyles from "@material-ui/core/styles/makeStyles";

import Home from "./page/Home";
import Logo from "./component/Logo";
import HowTo from "./page/HowTo";
import Newcomer from "./page/Newcomer";
import Guide from "./page/Guide";
import Docs from "./page/Docs";
import Tel from "./page/Tel";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 1000,
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    padding: "0 20px 200px 20px",
  },
  bonemash: {
    paddingTop: 60,
    height: 200,
  },
  subtitle: {
    fontWeight: 300,
    fontSize: 32,
    textAlign: "center",
  },
  buttonList: {
    textAlign: "center",
  },
  button: {
    margin: "0 5px 10px 5px",
  },
  selectedButton: {
    backgroundColor: theme.palette.grey[300],
    "&:hover": {
      backgroundColor: theme.palette.grey[300],
    },
  },
}));

function Layout({ location }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Logo className={classes.bonemash} />
      <p className={classes.subtitle}>AA Syd Online</p>
      <div className={classes.buttonList}>
        <Button
          component={Link}
          to="/"
          className={clsx([
            classes.button,
            location.pathname === "/" && classes.selectedButton,
          ])}
        >
          Hem
        </Button>
        <Button
          component={Link}
          to="/how-to"
          className={clsx([
            classes.button,
            location.pathname === "/how-to" && classes.selectedButton,
          ])}
        >
          Hur man deltar
        </Button>
        <Button
          component={Link}
          to="/newcomer"
          className={clsx([
            classes.button,
            location.pathname === "/newcomer" && classes.selectedButton,
          ])}
        >
          Ny i AA?
        </Button>
        <Button
          component={Link}
          to="/guide"
          className={clsx([
            classes.button,
            location.pathname === "/guide" && classes.selectedButton,
          ])}
        >
          Till inledaren
        </Button>
        <Button
          component={Link}
          to="/docs/none"
          className={clsx([
            classes.button,
            location.pathname.startsWith("/docs") && classes.selectedButton,
          ])}
        >
          Dokument
        </Button>
      </div>
      <Switch>
        <Route
          exact
          path="/"
          render={(routeProps) => <Home {...routeProps} />}
        />
        <Route
          exact
          path="/how-to"
          render={(routeProps) => <HowTo {...routeProps} />}
        />
        <Route
          exact
          path="/newcomer"
          render={(routeProps) => <Newcomer {...routeProps} />}
        />
        <Route
          exact
          path="/guide"
          render={(routeProps) => <Guide {...routeProps} />}
        />
        <Route
          exact
          path="/docs/:docId"
          render={(routeProps) => <Docs {...routeProps} />}
        />
        <Route
          exact
          path="/tel"
          render={(routeProps) => <Tel {...routeProps} />}
        />
        <Redirect to="/" />
      </Switch>
    </div>
  );
}

export default Layout;
