import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";

import useSkinny from "../hook/useSkinny";

const useStyles = makeStyles({
  root: {
    position: "fixed",
    top: 20,
    left: 20,
    margin: 0,
    "@media print": {
      display: "none",
    },
  },
});

function SkinnyControl() {
  const classes = useStyles();
  const [skinny, handleSkinnyChange] = useSkinny();
  return (
    <FormControlLabel
      className={classes.root}
      checked={skinny}
      onChange={handleSkinnyChange}
      control={<Checkbox />}
      label="Skärmdelningsläge"
      labelPlacement="end"
    />
  );
}

export default React.memo(SkinnyControl);
