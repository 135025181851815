import React, { useCallback, useMemo, useState } from "react";

import H1 from "../component/H1";
import P from "../component/P";
import { withStyles } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Collapse from "@material-ui/core/Collapse";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import ExpandMore from "@material-ui/icons/ExpandMore";
import CardContent from "@material-ui/core/CardContent";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import clsx from "clsx";
import BlueCardClosed from "../docs/BlueCardClosed";
import Intro from "../docs/Intro";
import HowItWorks from "../docs/HowItWorks";
import Prayer from "../docs/Prayer";
import { getFromLocalStorage, setToLocalStorage } from "../storage";
import useSkinny from "../hook/useSkinny";
import SkinnyControl from "../component/SkinnyControl";
import BlueCardOpen from "../docs/BlueCardOpen";
import FirstStep from "../docs/FirstStep";
import {getMonth, nextSunday} from "date-fns";
import Options from "../component/Options";
import PrintControl from "../component/PrintControl";
import usePrint from "../hook/usePrint";
import Meditation from "../docs/Meditation";

const classes = (theme) => ({
  root: {
    colorAdjust: "exact",
  },
  small: {
    fontSize: 14,
    fontStyle: "italic",
    fontWeight: 300,
  },
  chat: {
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: "bold",
  },
  speak: {
    fontSize: 18,
    fontStyle: "normal",
    fontWeight: 400,
  },
  textField: {
    marginBottom: 10,
  },
  textFieldWrapper: {
    display: "flex",
    flexDirection: "column",
    width: 200,
    marginLeft: 40,
    marginBottom: 40,
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  cardHeader: {
    cursor: "pointer",
  },
  skinny: {
    width: 500,
    margin: "auto",
  },
});

const BLUE_CARD_READER = "blueCardReader";
const INTRO_READER = "introReader";
const HOW_READER = "howReader";
const SHOW_BLUE_CARD = "showBlueCard";
const SHOW_INTRO = "showIntro";
const SHOW_HOW = "showHow";
const SHOW_PRAYER = "showPrayer";
const SHOW_FIRST_STEP = "showFirstStep";
const SHOW_MEDITATION = "showMeditation";
const OPEN_MEETING = "openMeeting";
const OPEN_MEETING_LABEL = "Öppet möte";
const OPEN_MEETING_LABEL_NOT = "Stängt möte";
const SPEAKER_MEETING = "speakerMeeting";
const SPEAKER_MEETING_LABEL = "Talarmöte";
const SPEAKER_MEETING_LABEL_NOT = "Inte talarmöte";
const FREE_MEETING = "freeMeeting";
const FREE_MEETING_LABEL = "Fritt ord";
const FREE_MEETING_LABEL_NOT = "Inte fritt ord";
const SPEAKER_MEETING_HEADS_UP_LABEL =
  "Arbetsmöte och talarmöte nästa söndag (sista söndagen i månaden)";

const textCardClasses = (theme) => ({
  small: {
    fontSize: 16,
    fontStyle: "italic",
    fontWeight: 300,
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  cardHeader: {
    cursor: "pointer",
  },
});

const TextCard = withStyles(textCardClasses)(
  ({ onToggle, title, show, content, classes }) => {
    return (
      <Card>
        <CardHeader
          disableTypography
          title={<span className={classes.small}>{title}</span>}
          onClick={onToggle}
          className={classes.cardHeader}
          action={
            <IconButton
              className={clsx([classes.expand, show && classes.expandOpen])}
              disableRipple
            >
              <ExpandMore />
            </IconButton>
          }
        />
        <Collapse in={show}>
          <CardContent>{content}</CardContent>
        </Collapse>
      </Card>
    );
  }
);

function calcIfNextSundayIsSpeakerMeeting() {
  const today = new Date();
  return (
    getMonth(nextSunday(today)) !==
    getMonth(nextSunday(nextSunday(today)))
  );
}

function Guide({ classes }) {
  const [blueCardReader, setBlueCardReader] = useState(
    getFromLocalStorage(BLUE_CARD_READER) || ""
  );
  const handleBlueCardReaderChange = useCallback((event) => {
    const value = event.target.value;
    setBlueCardReader(value);
    setToLocalStorage({ key: BLUE_CARD_READER, value });
  }, []);

  const [introReader, setIntroReader] = useState(
    getFromLocalStorage(INTRO_READER) || ""
  );
  const handleIntroReaderChange = useCallback((event) => {
    const value = event.target.value;
    setIntroReader(value);
    setToLocalStorage({ key: INTRO_READER, value });
  }, []);

  const [howReader, setHowReader] = useState(
    getFromLocalStorage(HOW_READER) || ""
  );
  const handleHowReaderChange = useCallback((event) => {
    const value = event.target.value;
    setHowReader(value);
    setToLocalStorage({ key: HOW_READER, value });
  }, []);

  const [showBlueCard, setShowBlueCard] = useState(
    getFromLocalStorage(SHOW_BLUE_CARD) === "true"
  );
  const handleToggleShowBlueCard = useCallback(() => {
    setShowBlueCard((prev) => {
      const value = !prev;
      setToLocalStorage({ key: SHOW_BLUE_CARD, value });
      return value;
    });
  }, []);

  const [showIntro, setShowIntro] = useState(
    getFromLocalStorage(SHOW_INTRO) === "true"
  );
  const handleToggleShowIntro = useCallback(() => {
    setShowIntro((prev) => {
      const value = !prev;
      setToLocalStorage({ key: SHOW_INTRO, value });
      return value;
    });
  }, []);

  const [showHow, setShowHow] = useState(
    getFromLocalStorage(SHOW_HOW) === "true"
  );
  const handleToggleShowHow = useCallback(() => {
    setShowHow((prev) => {
      const value = !prev;
      setToLocalStorage({ key: SHOW_HOW, value });
      return value;
    });
  }, []);

  const [showPrayer, setShowPrayer] = useState(
    getFromLocalStorage(SHOW_PRAYER) === "true"
  );
  const handleToggleShowPrayer = useCallback(() => {
    setShowPrayer((prev) => {
      const value = !prev;
      setToLocalStorage({ key: SHOW_PRAYER, value });
      return value;
    });
  }, []);

  const [showFirstStep, setShowFirstStep] = useState(
    getFromLocalStorage(SHOW_FIRST_STEP) === "true"
  );
  const handleToggleShowFirstStep = useCallback(() => {
    setShowFirstStep((prev) => {
      const value = !prev;
      setToLocalStorage({ key: SHOW_FIRST_STEP, value });
      return value;
    });
  }, []);

  const [showMeditation, setShowMeditation] = useState(
    getFromLocalStorage(SHOW_MEDITATION) === "true"
  );
  const handleToggleShowMeditation = useCallback(() => {
    setShowMeditation((prev) => {
      const value = !prev;
      setToLocalStorage({ key: SHOW_MEDITATION, value });
      return value;
    });
  }, []);

  const [skinny] = useSkinny();
  const [print] = usePrint();

  const [openMeeting, setOpenMeeting] = useState(
    getFromLocalStorage(OPEN_MEETING) === "true"
  );
  const handleToggleOpenMeeting = useCallback(() => {
    setOpenMeeting((prev) => {
      const value = !prev;
      setToLocalStorage({ key: OPEN_MEETING, value });
      return value;
    });
  }, []);

  const [speakerMeeting, setSpeakerMeeting] = useState(
    getFromLocalStorage(SPEAKER_MEETING) === "true"
  );
  const handleToggleSpeakerMeeting = useCallback(() => {
    setSpeakerMeeting((prev) => {
      const value = !prev;
      setToLocalStorage({ key: SPEAKER_MEETING, value });
      return value;
    });
  }, []);

  const [freeMeeting, setFreeMeeting] = useState(() => {
    const value = getFromLocalStorage(FREE_MEETING);
    if (value === null) {
      setToLocalStorage({ key: FREE_MEETING, value: "true" });
      return true;
    } else {
      return value === "true";
    }
  });
  const handleToggleFreeMeeting = useCallback(() => {
    setFreeMeeting((prev) => {
      const value = !prev;
      setToLocalStorage({ key: FREE_MEETING, value });
      return value;
    });
  }, []);

  const nextSundayIsSpeakerMeeting = useMemo(() => {
    return calcIfNextSundayIsSpeakerMeeting();
  }, []);

  return (
    <div className={classes.root}>
      <SkinnyControl />
      <PrintControl />
      <div className={clsx([skinny && classes.skinny])}>
        <H1>Till inledaren</H1>
        {!print && (
          <>
            <FormControlLabel
              checked={openMeeting}
              onChange={handleToggleOpenMeeting}
              control={<Checkbox />}
              label={OPEN_MEETING_LABEL}
              labelPlacement="end"
            />
            <FormControlLabel
              checked={speakerMeeting}
              onChange={handleToggleSpeakerMeeting}
              control={<Checkbox />}
              label={SPEAKER_MEETING_LABEL}
              labelPlacement="end"
            />
            <FormControlLabel
              checked={freeMeeting}
              onChange={handleToggleFreeMeeting}
              control={<Checkbox />}
              label={FREE_MEETING_LABEL}
              labelPlacement="end"
            />
            {nextSundayIsSpeakerMeeting && (
              <FormControlLabel
                checked={true}
                disabled
                control={<Checkbox />}
                label="Arbetsmöte och talarmöte nästa söndag"
                labelPlacement="end"
              />
            )}
          </>
        )}
        <P className={classes.small}>
          Innan mötet börjar, fråga vem som vill läsa följande, och anteckna det
          här:
        </P>
        <div className={classes.textFieldWrapper}>
          <TextField
            className={classes.textField}
            label="Blå kortet"
            value={blueCardReader}
            onChange={handleBlueCardReaderChange}
          />
          <TextField
            className={classes.textField}
            label="AA:s deklaration"
            value={introReader}
            onChange={handleIntroReaderChange}
          />
          <TextField
            className={classes.textField}
            label="Hur det fungerar"
            value={howReader}
            onChange={handleHowReaderChange}
          />
        </div>
        {!print && (
          <>
            <P className={classes.small}>
              Skicka också ut detta i chatten till alla deltagare:
            </P>
            <div className={classes.chat}>
              <Options
                showAll={print}
                value={openMeeting}
                options={[
                  {
                    value: true,
                    content: (
                      <>
                        Blå kortet (öppet):
                        https://aa-syd-online.se/docs/bluecardopen
                        <br />
                        <br />
                      </>
                    ),
                    label: OPEN_MEETING_LABEL,
                  },
                  {
                    value: false,
                    content: (
                      <>
                        Blå kortet (slutet):
                        https://aa-syd-online.se/docs/bluecard
                        <br />
                        <br />
                      </>
                    ),
                    label: OPEN_MEETING_LABEL_NOT,
                  },
                ]}
              />
              AA:s deklaration: https://aa-syd-online.se/docs/intro
              <br />
              <br />
              Hur det fungerar: https://aa-syd-online.se/docs/how
              <br />
            </div>
            <br />
          </>
        )}
        <P className={classes.speak}>
          Välkommen till AA-möte hos AA Syd Online, jag heter ... och är
          alkoholist. Vänligen kom ihåg att stänga av din mikrofon när du INTE
          pratar genom att klicka på <i>Mute</i> -knappen nere till vänster.
          Glömmer du att göra det så kommer jag göra det åt dig, men försök
          komma ihåg det själv så flyter mötet på bättre. Vill du ha video
          aktiverad får du gärna det, särskilt under tiden du delar, men det är
          inget krav.
        </P>
        <Options
          showAll={print}
          value={openMeeting}
          options={[
            {
              value: true,
              label: OPEN_MEETING_LABEL,
              content: (
                <P className={classes.speak}>
                  Detta möte är öppet för besökare såsom AA-nyfikna, anhöriga,
                  m.m, och fungerar såhär. Vi börjar med att presentera oss,
                  sedan läses inledande texter och eventuellt en tematext ur AAs
                  litteratur. Mötet öppnas sedan för delningar vilket innebär
                  att de som vill delar med sig om något som rör alkoholism;
                  kanske något man känner igen från temat eller något annat man
                  har på hjärtat. Du som besökare är också välkommen att dela om
                  något du tänker på eller kanske vad som fört dig hit. Vi
                  kommenterar inte andras delningar direkt och har du frågor ber
                  vi dig vänta med dem tills efter mötet.
                </P>
              ),
            },
          ]}
        />
        <P className={classes.speak}>
          Vi börjar med en presentationsrunda och jag ber därför alla sätta på
          sina mikrofoner. Jag kommer nu ropa upp alla mötesdeltagare, en efter
          en, så att du kan presentera dig och vi andra hälsa dig välkommen. Vi
          börjar överst i listan med...
        </P>
        <P className={classes.small}>
          <i>
            Läs upp alla deltagare en och en. Presenterar dem sig inte, hälsa
            dem ändå välkomna och gå vidare till nästa deltagare.
          </i>
        </P>
        <P className={classes.speak}>
          Missade jag att ropa upp någon?{" "}
          <span className={classes.small}>(...)</span> Nej, då ber jag att alla
          stänger av sina mikrofoner. Vi börjar mötet med en stunds tystnad och
          tänker efter varför just vi är här idag, och om vi orkar skänker vi en
          tanke till dem som fortfarande lider.
        </P>
        <P className={classes.speak}>
          Tack. Jag har bett <b>{(!print && blueCardReader) || "..."}</b> läsa{" "}
          <i>Blå kortet</i>.
        </P>
        <TextCard
          title="(Blå kortet läses)"
          show={showBlueCard}
          onToggle={handleToggleShowBlueCard}
          content={openMeeting ? <BlueCardOpen /> : <BlueCardClosed />}
        />
        <P className={classes.speak}>
          Tack. Jag har bett <b>{(!print && introReader) || "..."}</b> läsa{" "}
          <i>AA:s deklaration</i>.
        </P>
        <TextCard
          title="(AA:s deklaration läses)"
          show={showIntro}
          onToggle={handleToggleShowIntro}
          content={<Intro />}
        />
        <P className={classes.speak}>
          Tack. Om någon druckit alkohol eller tagit andra sinnesförändrande
          droger idag ber vi den personen att endast lyssna och istället
          kontakta någon av oss efter mötet. Detta är för gruppens trygghet.
        </P>
        <P className={classes.speak}>
          Är det någon här som är på sitt allra första AA-möte idag?{" "}
          <span className={classes.small}>
            (Varmt välkommen, stanna gärna kvar efter mötet om du har några
            frågor.)
          </span>
        </P>
        <P className={classes.speak}>
          Är det någon som besöker gruppen för första gången?{" "}
          <span className={classes.small}>Varmt välkommen.</span>
        </P>
        <P className={classes.speak}>Då är vi alla lika välkomna.</P>
        <P className={classes.speak}>
          Är det någon som firar nykter tid idag?{" "}
          <span className={classes.small}>
            (Stort grattis och tack för att du visar att det fungerar.)
          </span>
        </P>
        <P className={classes.speak}>Är vi nyktra för idag?</P>
        <P className={classes.speak}>
          Jag har bett <b>{(!print && howReader) || "..."}</b> läsa{" "}
          <i>Hur det fungerar</i>.
        </P>
        <TextCard
          title="(Hur det fungerar läses)"
          show={showHow}
          onToggle={handleToggleShowHow}
          content={<HowItWorks />}
        />
        <P className={classes.speak}>
          Tack. Vi vill framhålla vikten av arbete i 12-stegsprogrammet som ett
          led i personligt tillfrisknande. Om du vill vara delaktig i den här
          AA-gruppen med service så kontakta mig efter mötet. Det här mötet
          varar fram till klockan 21, tänk på mötestiden så att alla får
          möjlighet att dela. Vi håller oss till max 5 minuters delning per
          person till att börja med, och jag kommer påminna dig ifall tiden
          överskrids. Finns det sedan tid över får man gärna dela igen.
        </P>
        <Options
          showAll={print}
          value={speakerMeeting}
          options={[
            {
              value: true,
              label: SPEAKER_MEETING_LABEL,
              content: (
                <>
                  <P className={classes.speak}>
                    Idag är det talarmöte, vilket innebär att vi inleder med en
                    20 minuter lång berättelse från en tillfrisknande medlem i
                    AA som ska berätta om sin alkoholism. Efter det fortsätter
                    mötet med delningar som vanligt. Och med det lämnar jag över
                    ordet till dagens talare: ...
                  </P>
                  <P className={classes.small}>(Talaren läser i 20 minuter)</P>
                </>
              ),
            },
            {
              value: false,
              label: SPEAKER_MEETING_LABEL_NOT,
              content: (
                <>
                  <P className={classes.speak}>
                    Kvällens ämne är ... Förslagsvis delar vi om något som har
                    med detta att göra, eller vadhelst annat som rör vår
                    alkoholism.
                  </P>
                  <P className={classes.small}>
                    (Inledaren läser text och/eller delar kring ämnet)
                  </P>
                  <TextCard
                    title="(Utdrag ur första steget läses ifall någon är på sitt första AA-möte)"
                    show={showFirstStep}
                    onToggle={handleToggleShowFirstStep}
                    content={<FirstStep />}
                  />
                </>
              ),
            },
          ]}
        />
        <P className={classes.speak}>Tack så mycket.</P>
        <Options
          showAll={print}
          value={freeMeeting}
          options={[
            {
              value: true,
              label: FREE_MEETING_LABEL,
              content: (
                <>
                  <P className={classes.speak}>
                    Nu öppnas mötet för delning och det är fritt fram att begära
                    ordet, presentera sig och dela, och jag vill påminna om att
                    vi <u>inte</u> kommenterar varandras delningar. Varsågoda.
                  </P>
                  <P className={classes.small}>---</P>
                  <P className={classes.small}>Delningar</P>
                  <P className={classes.small}>---</P>
                </>
              ),
            },
            {
              value: false,
              label: FREE_MEETING_LABEL_NOT,
              content: (
                <>
                  <P className={classes.speak}>
                    Jag kommer nu att dela ut ordet i samma ordning som under
                    presentationsrundan, så att alla får möjlighet att
                    presentera sig och dela. Vill man inte dela så är det helt
                    okej att bara presentera sig och skicka ordet vidare. Jag
                    vill även påminna om att vi <u>inte</u> kommenterar
                    varandras delningar.
                  </P>
                  <P className={classes.small}>Börja med att dela själv.</P>
                  <P className={classes.speak}>
                    Då börjar jag med att lämna ordet vidare till ...
                  </P>
                  <>
                    <P className={classes.small}>---</P>
                    <P className={classes.small}>
                      Delningar (ta upp och lämna ordet vidare till nästa person
                      efter varje delning)
                    </P>
                    <P className={classes.small}>---</P>
                  </>
                </>
              ),
            },
          ]}
        />
        <TextCard
          title="(Läses ifall det finns mycket tid över och mötet ska meditera)"
          show={showMeditation}
          onToggle={handleToggleShowMeditation}
          content={<Meditation />}
        />
        <br />
        <P className={classes.small}>
          Avslutning av mötet, skicka ut detta i chatten till alla deltagare:
        </P>
        <P className={classes.chat}>
          AA Syd Online's hemsida: https://aa-syd-online.se
          <br/>
          <br/>
          Swish till Jonas: 070-655 73 01 (Meddelande: "Hatten")
        </P>
        <br/>
        <P className={classes.speak}>
          Innan jag går på avslut undrar jag om det finns några andra AA-relaterade meddelanden?
        </P>
        <P className={classes.speak}>
          Vi vill tacka dem som gjort det här mötet möjligt, mötesansvarig och
          övriga mötesdeltagare. Enligt AA:s 7e tradition är vi självförsörjande
          och tar inte emot bidrag utifrån. Medlemmar i AA är välkomna att
          swisha in valfri summa till gruppen. Swishnummer hittar du i chatten
          och märk betalningen med <i>Hatten</i>. Nummer och mottagare har nyligen ändrats, så vänligen kontrollera att
          du swishar till Jonas. Pengarna går till att täcka
          gruppens omkostnader och överskottet till AA:s centrala verksamheter i
          Sverige.
        </P>
        <P className={classes.speak}>
          Enligt AA:s 12:e tradition är anonymiteten vår andliga grundval. Vad
          som sagts här och vem som deltagit låter vi stanna här. Vill du tipsa
          en annan alkoholist om att detta möte finns, hänvisa då gärna till vår
          hemsida där vi samlar all information. Adressen till hemsidan hittar
          du i chatten.
        </P>
        <P className={classes.speak}>
          Om du letar sponsor, vill tala på ett talarmöte eller har andra frågor
          så stanna gärna kvar efter mötet.
        </P>
        <Options
          showAll={print}
          value={nextSundayIsSpeakerMeeting}
          options={[
            {
              value: true,
              label: SPEAKER_MEETING_HEADS_UP_LABEL,
              content: (
                <>
                  <P className={classes.speak}>
                    Nästa söndag klockan 19 har gruppen arbetsmöte. Arbetsmötet
                    är gruppens beslutande organ och här får alla medlemmar
                    närvara. Kom gärna dit för att hålla koll på vad gruppen gör
                    och komma med förslag på hur vi kan bli bättre.
                  </P>
                  <P className={classes.speak}>
                    Nästa söndag är det också talarmöte. Detta möte inleds med
                    en 20 minuters berättelse från en tillfrisknande alkoholist
                    som delar med sig av hur det var, vad som hände och hur det
                    nu är. Kom gärna hit och lyssna!
                  </P>
                </>
              ),
            },
          ]}
        />
        <P className={classes.speak}>
          Jag kommer nu avsluta mötet genom att läsa <i>Sinnesrobönen</i>. Läs
          gärna med, men vänligen håll din mikrofon avstängd, eftersom det finns
          en fördröjning i ljudet och det annars blir väldigt svårt att följa
          med.
        </P>
        <TextCard
          title="(Sinnesrobönen läses)"
          show={showPrayer}
          onToggle={handleToggleShowPrayer}
          content={<Prayer />}
        />
        <P className={classes.speak}>
          Tack så mycket allihopa, och ta hand om er.
        </P>
      </div>
    </div>
  );
}

export default withStyles(classes)(Guide);
